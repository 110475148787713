import validate from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/node_modules/nuxt/dist/pages/runtime/validate.js";
import maintenance_45global from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/middleware/maintenance.global.ts";
import manifest_45route_45rule from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  maintenance_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/middleware/admin.ts"),
  "agreed-responsable-terms": () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/middleware/agreed-responsable-terms.ts"),
  authenticated: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/middleware/authenticated.ts"),
  guest: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/middleware/guest.ts"),
  "old-search-redirections": () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/middleware/old-search-redirections.ts")
}