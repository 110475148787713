import { default as accessibiliteTpjT8mLahFMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/accessibilite.vue?macro=true";
import { default as _91slug_93tZig1sVh9dMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/activites/[slug].vue?macro=true";
import { default as editr4IFzcOsf7Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/activites/[id]/edit.vue?macro=true";
import { default as indexcSe7CKuh2YMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/activites/[id]/index.vue?macro=true";
import { default as addVYa3XQJhRLMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/activites/add.vue?macro=true";
import { default as indexZ912zxH9KVMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/activites/index.vue?macro=true";
import { default as editY1MLBHh0XwMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/domaines/[id]/edit.vue?macro=true";
import { default as addLIXfP6LlksMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/domaines/add.vue?macro=true";
import { default as indexU9GOp6HSKMMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/domaines/index.vue?macro=true";
import { default as edit82QPVD7tNRMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/modeles-mission/[id]/edit.vue?macro=true";
import { default as addM8tL9SMoMwMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/modeles-mission/add.vue?macro=true";
import { default as index641tHY1FmXMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/modeles-mission/index.vue?macro=true";
import { default as postsnLpF7MwoY1Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/posts.vue?macro=true";
import { default as editFxLQjWQJ4SMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/reseaux/[id]/edit.vue?macro=true";
import { default as indexBIhxlLBpnaMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/reseaux/[id]/index.vue?macro=true";
import { default as addljpLv76wIqMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/reseaux/add.vue?macro=true";
import { default as indexDN5Ucmdp8IMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/reseaux/index.vue?macro=true";
import { default as editqMBknfG8oJMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/ressources/[id]/edit.vue?macro=true";
import { default as addFqypL6hsTmMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/ressources/add.vue?macro=true";
import { default as indexv3iB0psfPTMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/ressources/index.vue?macro=true";
import { default as edit26eKMgDxO7Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/territoires/[id]/edit.vue?macro=true";
import { default as indexSwVkVcFKSjMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/territoires/[id]/index.vue?macro=true";
import { default as addkXkPDRLELBMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/territoires/add.vue?macro=true";
import { default as indexqa6BYmdNdBMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/territoires/index.vue?macro=true";
import { default as edit2mDNrbFKGpMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/testimonials/[id]/edit.vue?macro=true";
import { default as indexrUcGkvZEkvMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/testimonials/index.vue?macro=true";
import { default as index1xDZJiQpipMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/index.vue?macro=true";
import { default as benevoles_45informationsZbOPvB4xr3Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/benevoles-informations.vue?macro=true";
import { default as benevolesZVxA8crVVsMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/benevoles.vue?macro=true";
import { default as datesi5hmHUP8USMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/dates.vue?macro=true";
import { default as indexiDzueg5d06Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/index.vue?macro=true";
import { default as informationsfZ0LZKSEsXMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/informations.vue?macro=true";
import { default as lieuxmyUbO42vwtMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/lieux.vue?macro=true";
import { default as responsablespiBvChs75vMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/responsables.vue?macro=true";
import { default as titleWVRC09KAo7Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/title.vue?macro=true";
import { default as indexxz2qq1OQSxMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/trouver-des-benevoles/index.vue?macro=true";
import { default as redirectS9zS0JN6tpMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/trouver-des-benevoles/redirect.vue?macro=true";
import { default as visueljOOmr8Bh80Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/visuel.vue?macro=true";
import { default as indexoAAqCZmUrWMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/index.vue?macro=true";
import { default as notesISGFwa5VAQMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/notes.vue?macro=true";
import { default as editS5kW15UTgvMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/organisations/[id]/edit.vue?macro=true";
import { default as indexvriTIjtfllMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/organisations/[id]/index.vue?macro=true";
import { default as addxPV1xoc7rKMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/organisations/[id]/missions/add.vue?macro=true";
import { default as indexuUBhRCyUOlMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/organisations/index.vue?macro=true";
import { default as invitations8YumqZRnPwMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/other/invitations.vue?macro=true";
import { default as logsK90jZwKWGiMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/other/logs.vue?macro=true";
import { default as indexH7CDtzuvXCMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/participations/index.vue?macro=true";
import { default as ressourcesvSmSe8w4r7Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/ressources.vue?macro=true";
import { default as emailsr2PqxKiWCsMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/settings/emails.vue?macro=true";
import { default as messagesOPjZ5dE4KMMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/settings/messages.vue?macro=true";
import { default as editGwePCTRscsMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/settings/rules/[id]/edit.vue?macro=true";
import { default as pending_45itemsCs3tfAbiMXMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/settings/rules/[id]/pending-items.vue?macro=true";
import { default as addOBoB8zeomFMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/settings/rules/add.vue?macro=true";
import { default as indexWi1OmLD9BtMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/settings/rules/index.vue?macro=true";
import { default as trafic_45entrantMyZyfTNZo5Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/api-engagement/trafic-entrant.vue?macro=true";
import { default as trafic_45sortantr1p45knqWpMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/api-engagement/trafic-sortant.vue?macro=true";
import { default as conversionsZqei8yfG26Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/conversions.vue?macro=true";
import { default as missions_45a_45validervYCMhOXEgJMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/departements/missions-a-valider.vue?macro=true";
import { default as missions_45perimeesLT09URHTlIMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/departements/missions-perimees.vue?macro=true";
import { default as organisations_45a_45valider41DSKGhi6yMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/departements/organisations-a-valider.vue?macro=true";
import { default as indexzfneduuM7OMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/index.vue?macro=true";
import { default as indicateurs_45clesqx1AD2SCtQMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/indicateurs-cles.vue?macro=true";
import { default as missionsyongdQSWi1Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/missions.vue?macro=true";
import { default as indexzpVZYLMX4aMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/organisations/index.vue?macro=true";
import { default as missions_45perimeesM35J6gGe1JMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/organisations/missions-perimees.vue?macro=true";
import { default as participations_45a_45validerIRd3r2qcdZMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/organisations/participations-a-valider.vue?macro=true";
import { default as participations_45refusees_45annulees5sXhvl6t5bMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/organisations/participations-refusees-annulees.vue?macro=true";
import { default as participationsUDfe70bTZLMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/participations.vue?macro=true";
import { default as placesxsTIpyVXwfMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/places.vue?macro=true";
import { default as moderationsuKNnEV7glYMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/suivis/moderations.vue?macro=true";
import { default as utilisateursaRlY5to8UoMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/utilisateurs.vue?macro=true";
import { default as editOItZEPitFCMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/taxonomies/[slug]/[id]/edit.vue?macro=true";
import { default as addVkLc9pPsRVMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/taxonomies/[slug]/add.vue?macro=true";
import { default as indexQ5BnHQdXMRMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/taxonomies/[slug]/index.vue?macro=true";
import { default as temoignagesVUiKuYUD2HMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/temoignages.vue?macro=true";
import { default as editS94vQON6g3Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/utilisateurs/[id]/edit.vue?macro=true";
import { default as indexryyqtdVPVRMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/utilisateurs/[id]/index.vue?macro=true";
import { default as indexPAaoc8iI2aMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/utilisateurs/index.vue?macro=true";
import { default as benevoleszQQ318fs0KMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/charte-bon-fonctionnement/benevoles.vue?macro=true";
import { default as indexKfGlPwLbXdMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/charte-bon-fonctionnement/index.vue?macro=true";
import { default as responsablesOSeFyCw3A5Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/charte-bon-fonctionnement/responsables.vue?macro=true";
import { default as charte_45reserve_45civiquelUhpZYCglFMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/charte-reserve-civique.vue?macro=true";
import { default as conditions_45generales_45d_45utilisationmJ1bksmG21Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/conditions-generales-d-utilisation.vue?macro=true";
import { default as indexcuIIJ0SoixMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/dashboard/index.vue?macro=true";
import { default as _91slug_93cpoZiVJdPTMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/departements/[slug].vue?macro=true";
import { default as _91slug_93sQ6HH3SAuIMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/domaines-action/[slug].vue?macro=true";
import { default as en_45ce_45momentXMUq0DIUxXMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/en-ce-moment.vue?macro=true";
import { default as indexEI5bRFwFlAMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/index.vue?macro=true";
import { default as indexgg9PYzd9GoMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/benevole/index.vue?macro=true";
import { default as competencesG3xrfTSPudMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/benevole/step/competences.vue?macro=true";
import { default as disponibilitesBYiVIESQQZMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/benevole/step/disponibilites.vue?macro=true";
import { default as preferencesDtCRKimiEqMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/benevole/step/preferences.vue?macro=true";
import { default as profileXoxU5R9Hq8Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/benevole/step/profile.vue?macro=true";
import { default as index6MnrGCw6a8Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/index.vue?macro=true";
import { default as indexDmtjcuRt0RMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/responsable/index.vue?macro=true";
import { default as charte_45bon_45fonctionnementELUPtwWs7fMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/responsable/step/charte-bon-fonctionnement.vue?macro=true";
import { default as collectivite_45confirmation3nQOZiJkCWMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/responsable/step/collectivite-confirmation.vue?macro=true";
import { default as collectivite8CXZjIjB2XMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/responsable/step/collectivite.vue?macro=true";
import { default as organisation_45confirmation0uvnB1NaniMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/responsable/step/organisation-confirmation.vue?macro=true";
import { default as organisation_45detailsTCyJ93fJLxMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/responsable/step/organisation-details.vue?macro=true";
import { default as organisation_45images6W04KxQ8VdMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/responsable/step/organisation-images.vue?macro=true";
import { default as organisationw7cQ9eemPiMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/responsable/step/organisation.vue?macro=true";
import { default as profilezBTyiPVPbRMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/responsable/step/profile.vue?macro=true";
import { default as _91hash_93g20uvCFVPQMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/invitations/[hash].vue?macro=true";
import { default as logintSIX407gGGMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/login.vue?macro=true";
import { default as adminrkKD6TeLf8Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/maintenance/admin.vue?macro=true";
import { default as indexOUNRwrTd51Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/maintenance/index.vue?macro=true";
import { default as mentions_45legalesW7Cb6RfA7PMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/mentions-legales.vue?macro=true";
import { default as _91id_93wVEfXGR85kMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/messages/[id].vue?macro=true";
import { default as indexS6EgUOAqnUMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/messages/index.vue?macro=true";
import { default as addixUBnz6zH5Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/messages/modeles/add.vue?macro=true";
import { default as indexL2EuWAv9k3Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/messages/modeles/index.vue?macro=true";
import { default as _91slug_93CVFBjeYLpqMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/missions-benevolat/[id]/[slug].vue?macro=true";
import { default as indexYAOTXJdmXpMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/missions-benevolat/[id]/index.vue?macro=true";
import { default as indexyAYEIvC4wEMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/missions-benevolat/index.vue?macro=true";
import { default as notificationsefMKdgKZqNMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/notifications.vue?macro=true";
import { default as _91slug_93Z32UmxDdveMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/organisations/[slug].vue?macro=true";
import { default as indexcCCU3qivYSMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/organisations/index.vue?macro=true";
import { default as _91token_93vdMbxg1i94Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/password-reset/[token].vue?macro=true";
import { default as indexvhplbkNpnjMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/password-reset/index.vue?macro=true";
import { default as plan_45du_45sitercrcDyzszSMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/plan-du-site.vue?macro=true";
import { default as politique_45de_45confidentialiteN7QOY2eBleMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/politique-de-confidentialite.vue?macro=true";
import { default as indexE6xBz3hIkmMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/profile/alertes/index.vue?macro=true";
import { default as charte_45bon_45fonctionnement1uOAbsDDrEMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/profile/charte-bon-fonctionnement.vue?macro=true";
import { default as editRwvfHfDZ7EMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/profile/edit.vue?macro=true";
import { default as favoris0Xn6qtN75zMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/profile/favoris.vue?macro=true";
import { default as indexJXKMuolDtnMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/profile/index.vue?macro=true";
import { default as missionsNAZ0hqjCUWMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/profile/missions.vue?macro=true";
import { default as notificationsHxoiPuGr5YMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/profile/notifications.vue?macro=true";
import { default as preferencesQ7FtJ8HQDUMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/profile/preferences.vue?macro=true";
import { default as settingsKbnLS0aRQXMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/profile/settings.vue?macro=true";
import { default as decembre_45ensemblePgHLS9FPWSMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/quiz/decembre-ensemble.vue?macro=true";
import { default as generiquesuBAPQmbrvMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/quiz/generique.vue?macro=true";
import { default as mgenjqR4E2FUxaMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/quiz/mgen.vue?macro=true";
import { default as printemps_45pour_45la_45planetejgLbgTONKlMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/quiz/printemps-pour-la-planete.vue?macro=true";
import { default as septembre_45pour_45apprendreNnCi3EerRiMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/quiz/septembre-pour-apprendre.vue?macro=true";
import { default as _91slug_93Bvzc0za9uoMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/reseaux/[slug].vue?macro=true";
import { default as indexcJ6d0MIIpOMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/stats/index.vue?macro=true";
import { default as missionsvvoZgGUulKMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/stats/missions.vue?macro=true";
import { default as organisationstDpIMuzlGvMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/stats/organisations.vue?macro=true";
import { default as participationspo7UM9UfsHMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/stats/participations.vue?macro=true";
import { default as placesrjsHweGncYMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/stats/places.vue?macro=true";
import { default as utilisateurs6GKenpluJDMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/stats/utilisateurs.vue?macro=true";
import { default as search_45archived_45users1qW4q43BlTMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/actions/search-archived-users.vue?macro=true";
import { default as transfert_45organisationbQaoTamquSMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/actions/transfert-organisation.vue?macro=true";
import { default as user_45reset_45context_45roleEVyjgOaHMRMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/actions/user-reset-context-role.vue?macro=true";
import { default as user_45reset_45password_45linkW3eSpTZCqtMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/actions/user-reset-password-link.vue?macro=true";
import { default as doublons_45organisationsLSClzPDOAYMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/contents/doublons-organisations.vue?macro=true";
import { default as doublons_45territoiresTmz2b0YvFdMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/contents/doublons-territoires.vue?macro=true";
import { default as indexsUoikpUp7gMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/index.vue?macro=true";
import { default as activity_45logs94j3uCHRXAMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/referents/activity-logs.vue?macro=true";
import { default as waiting_45actionsMCQoImYhYJMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/referents/waiting-actions.vue?macro=true";
import { default as missions_45outdatedfDKXGB2Cm8Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/responsables/missions-outdated.vue?macro=true";
import { default as participations_45to_45be_45treatedqZd1NLfYn3Meta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/responsables/participations-to-be-treated.vue?macro=true";
import { default as _91token_93ZP4lK2DaTtMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/temoignages/[token].vue?macro=true";
import { default as indexY4Vu1pvfeoMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/territoires/index.vue?macro=true";
import { default as _91slug_93dbbS9UluoCMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/villes/[slug].vue?macro=true";
import { default as component_45stubZECJ9rda2hMeta } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubZECJ9rda2h } from "/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "accessibilite",
    path: "/accessibilite",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/accessibilite.vue").then(m => m.default || m)
  },
  {
    name: "activites-slug",
    path: "/activites/:slug()",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/activites/[slug].vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites-id-edit",
    path: "/admin/contenus/activites/:id()/edit",
    meta: editr4IFzcOsf7Meta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/activites/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites-id",
    path: "/admin/contenus/activites/:id()",
    meta: indexcSe7CKuh2YMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/activites/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites-add",
    path: "/admin/contenus/activites/add",
    meta: addVYa3XQJhRLMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/activites/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites",
    path: "/admin/contenus/activites",
    meta: indexZ912zxH9KVMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/activites/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-domaines-id-edit",
    path: "/admin/contenus/domaines/:id()/edit",
    meta: editY1MLBHh0XwMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/domaines/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-domaines-add",
    path: "/admin/contenus/domaines/add",
    meta: addLIXfP6LlksMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/domaines/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-domaines",
    path: "/admin/contenus/domaines",
    meta: indexU9GOp6HSKMMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/domaines/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-modeles-mission-id-edit",
    path: "/admin/contenus/modeles-mission/:id()/edit",
    meta: edit82QPVD7tNRMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/modeles-mission/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-modeles-mission-add",
    path: "/admin/contenus/modeles-mission/add",
    meta: addM8tL9SMoMwMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/modeles-mission/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-modeles-mission",
    path: "/admin/contenus/modeles-mission",
    meta: index641tHY1FmXMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/modeles-mission/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-posts",
    path: "/admin/contenus/posts",
    meta: postsnLpF7MwoY1Meta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/posts.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux-id-edit",
    path: "/admin/contenus/reseaux/:id()/edit",
    meta: editFxLQjWQJ4SMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/reseaux/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux-id",
    path: "/admin/contenus/reseaux/:id()",
    meta: indexBIhxlLBpnaMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/reseaux/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux-add",
    path: "/admin/contenus/reseaux/add",
    meta: addljpLv76wIqMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/reseaux/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux",
    path: "/admin/contenus/reseaux",
    meta: indexDN5Ucmdp8IMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/reseaux/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-ressources-id-edit",
    path: "/admin/contenus/ressources/:id()/edit",
    meta: editqMBknfG8oJMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/ressources/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-ressources-add",
    path: "/admin/contenus/ressources/add",
    meta: addFqypL6hsTmMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/ressources/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-ressources",
    path: "/admin/contenus/ressources",
    meta: indexv3iB0psfPTMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/ressources/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires-id-edit",
    path: "/admin/contenus/territoires/:id()/edit",
    meta: edit26eKMgDxO7Meta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/territoires/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires-id",
    path: "/admin/contenus/territoires/:id()",
    meta: indexSwVkVcFKSjMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/territoires/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires-add",
    path: "/admin/contenus/territoires/add",
    meta: addkXkPDRLELBMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/territoires/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires",
    path: "/admin/contenus/territoires",
    meta: indexqa6BYmdNdBMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/territoires/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-testimonials-id-edit",
    path: "/admin/contenus/testimonials/:id()/edit",
    meta: edit2mDNrbFKGpMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/testimonials/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-testimonials",
    path: "/admin/contenus/testimonials",
    meta: indexrUcGkvZEkvMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/contenus/testimonials/index.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: index1xDZJiQpipMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-benevoles-informations",
    path: "/admin/missions/:id()/benevoles-informations",
    meta: benevoles_45informationsZbOPvB4xr3Meta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/benevoles-informations.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-benevoles",
    path: "/admin/missions/:id()/benevoles",
    meta: benevolesZVxA8crVVsMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/benevoles.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-dates",
    path: "/admin/missions/:id()/dates",
    meta: datesi5hmHUP8USMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/dates.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id",
    path: "/admin/missions/:id()",
    meta: indexiDzueg5d06Meta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-informations",
    path: "/admin/missions/:id()/informations",
    meta: informationsfZ0LZKSEsXMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/informations.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-lieux",
    path: "/admin/missions/:id()/lieux",
    meta: lieuxmyUbO42vwtMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/lieux.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-responsables",
    path: "/admin/missions/:id()/responsables",
    meta: responsablespiBvChs75vMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/responsables.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-title",
    path: "/admin/missions/:id()/title",
    meta: titleWVRC09KAo7Meta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/title.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-trouver-des-benevoles",
    path: "/admin/missions/:id()/trouver-des-benevoles",
    meta: indexxz2qq1OQSxMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/trouver-des-benevoles/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-trouver-des-benevoles-redirect",
    path: "/admin/missions/:id()/trouver-des-benevoles/redirect",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/trouver-des-benevoles/redirect.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-visuel",
    path: "/admin/missions/:id()/visuel",
    meta: visueljOOmr8Bh80Meta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/[id]/visuel.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions",
    path: "/admin/missions",
    meta: indexoAAqCZmUrWMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/missions/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-notes",
    path: "/admin/notes",
    meta: notesISGFwa5VAQMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/notes.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations-id-edit",
    path: "/admin/organisations/:id()/edit",
    meta: editS5kW15UTgvMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/organisations/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations-id",
    path: "/admin/organisations/:id()",
    meta: indexvriTIjtfllMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/organisations/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations-id-missions-add",
    path: "/admin/organisations/:id()/missions/add",
    meta: addxPV1xoc7rKMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/organisations/[id]/missions/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations",
    path: "/admin/organisations",
    meta: indexuUBhRCyUOlMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-other-invitations",
    path: "/admin/other/invitations",
    meta: invitations8YumqZRnPwMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/other/invitations.vue").then(m => m.default || m)
  },
  {
    name: "admin-other-logs",
    path: "/admin/other/logs",
    meta: logsK90jZwKWGiMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/other/logs.vue").then(m => m.default || m)
  },
  {
    name: "admin-participations",
    path: "/admin/participations",
    meta: indexH7CDtzuvXCMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/participations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-ressources",
    path: "/admin/ressources",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/ressources.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-emails",
    path: "/admin/settings/emails",
    meta: emailsr2PqxKiWCsMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/settings/emails.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-messages",
    path: "/admin/settings/messages",
    meta: messagesOPjZ5dE4KMMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/settings/messages.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules-id-edit",
    path: "/admin/settings/rules/:id()/edit",
    meta: editGwePCTRscsMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/settings/rules/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules-id-pending-items",
    path: "/admin/settings/rules/:id()/pending-items",
    meta: pending_45itemsCs3tfAbiMXMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/settings/rules/[id]/pending-items.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules-add",
    path: "/admin/settings/rules/add",
    meta: addOBoB8zeomFMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/settings/rules/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules",
    path: "/admin/settings/rules",
    meta: indexWi1OmLD9BtMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/settings/rules/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-api-engagement-trafic-entrant",
    path: "/admin/statistics/api-engagement/trafic-entrant",
    meta: trafic_45entrantMyZyfTNZo5Meta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/api-engagement/trafic-entrant.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-api-engagement-trafic-sortant",
    path: "/admin/statistics/api-engagement/trafic-sortant",
    meta: trafic_45sortantr1p45knqWpMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/api-engagement/trafic-sortant.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-conversions",
    path: "/admin/statistics/conversions",
    meta: conversionsZqei8yfG26Meta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/conversions.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-departements-missions-a-valider",
    path: "/admin/statistics/departements/missions-a-valider",
    meta: missions_45a_45validervYCMhOXEgJMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/departements/missions-a-valider.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-departements-missions-perimees",
    path: "/admin/statistics/departements/missions-perimees",
    meta: missions_45perimeesLT09URHTlIMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/departements/missions-perimees.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-departements-organisations-a-valider",
    path: "/admin/statistics/departements/organisations-a-valider",
    meta: organisations_45a_45valider41DSKGhi6yMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/departements/organisations-a-valider.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics",
    path: "/admin/statistics",
    meta: indexzfneduuM7OMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-indicateurs-cles",
    path: "/admin/statistics/indicateurs-cles",
    meta: indicateurs_45clesqx1AD2SCtQMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/indicateurs-cles.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-missions",
    path: "/admin/statistics/missions",
    meta: missionsyongdQSWi1Meta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/missions.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations",
    path: "/admin/statistics/organisations",
    meta: indexzpVZYLMX4aMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations-missions-perimees",
    path: "/admin/statistics/organisations/missions-perimees",
    meta: missions_45perimeesM35J6gGe1JMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/organisations/missions-perimees.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations-participations-a-valider",
    path: "/admin/statistics/organisations/participations-a-valider",
    meta: participations_45a_45validerIRd3r2qcdZMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/organisations/participations-a-valider.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations-participations-refusees-annulees",
    path: "/admin/statistics/organisations/participations-refusees-annulees",
    meta: participations_45refusees_45annulees5sXhvl6t5bMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/organisations/participations-refusees-annulees.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-participations",
    path: "/admin/statistics/participations",
    meta: participationsUDfe70bTZLMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/participations.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-places",
    path: "/admin/statistics/places",
    meta: placesxsTIpyVXwfMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/places.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-suivis-moderations",
    path: "/admin/statistics/suivis/moderations",
    meta: moderationsuKNnEV7glYMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/suivis/moderations.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-utilisateurs",
    path: "/admin/statistics/utilisateurs",
    meta: utilisateursaRlY5to8UoMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/statistics/utilisateurs.vue").then(m => m.default || m)
  },
  {
    name: "admin-taxonomies-slug-id-edit",
    path: "/admin/taxonomies/:slug()/:id()/edit",
    meta: editOItZEPitFCMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/taxonomies/[slug]/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-taxonomies-slug-add",
    path: "/admin/taxonomies/:slug()/add",
    meta: addVkLc9pPsRVMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/taxonomies/[slug]/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-taxonomies-slug",
    path: "/admin/taxonomies/:slug()",
    meta: indexQ5BnHQdXMRMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/taxonomies/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-temoignages",
    path: "/admin/temoignages",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/temoignages.vue").then(m => m.default || m)
  },
  {
    name: "admin-utilisateurs-id-edit",
    path: "/admin/utilisateurs/:id()/edit",
    meta: editS94vQON6g3Meta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/utilisateurs/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-utilisateurs-id",
    path: "/admin/utilisateurs/:id()",
    meta: indexryyqtdVPVRMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/utilisateurs/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-utilisateurs",
    path: "/admin/utilisateurs",
    meta: indexPAaoc8iI2aMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/admin/utilisateurs/index.vue").then(m => m.default || m)
  },
  {
    name: "charte-bon-fonctionnement-benevoles",
    path: "/charte-bon-fonctionnement/benevoles",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/charte-bon-fonctionnement/benevoles.vue").then(m => m.default || m)
  },
  {
    name: "charte-bon-fonctionnement",
    path: "/charte-bon-fonctionnement",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/charte-bon-fonctionnement/index.vue").then(m => m.default || m)
  },
  {
    name: "charte-bon-fonctionnement-responsables",
    path: "/charte-bon-fonctionnement/responsables",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/charte-bon-fonctionnement/responsables.vue").then(m => m.default || m)
  },
  {
    name: "charte-reserve-civique",
    path: "/charte-reserve-civique",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/charte-reserve-civique.vue").then(m => m.default || m)
  },
  {
    name: "conditions-generales-d-utilisation",
    path: "/conditions-generales-d-utilisation",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/conditions-generales-d-utilisation.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexcuIIJ0SoixMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "departements-slug",
    path: "/departements/:slug()",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/departements/[slug].vue").then(m => m.default || m)
  },
  {
    name: "domaines-action-slug",
    path: "/domaines-action/:slug()",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/domaines-action/[slug].vue").then(m => m.default || m)
  },
  {
    name: "en-ce-moment",
    path: "/en-ce-moment",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/en-ce-moment.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole",
    path: "/inscription/benevole",
    meta: indexgg9PYzd9GoMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/benevole/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-competences",
    path: "/inscription/benevole/step/competences",
    meta: competencesG3xrfTSPudMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/benevole/step/competences.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-disponibilites",
    path: "/inscription/benevole/step/disponibilites",
    meta: disponibilitesBYiVIESQQZMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/benevole/step/disponibilites.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-preferences",
    path: "/inscription/benevole/step/preferences",
    meta: preferencesDtCRKimiEqMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/benevole/step/preferences.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-profile",
    path: "/inscription/benevole/step/profile",
    meta: profileXoxU5R9Hq8Meta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/benevole/step/profile.vue").then(m => m.default || m)
  },
  {
    name: "inscription",
    path: "/inscription",
    meta: index6MnrGCw6a8Meta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable",
    path: "/inscription/responsable",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/responsable/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-charte-bon-fonctionnement",
    path: "/inscription/responsable/step/charte-bon-fonctionnement",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/responsable/step/charte-bon-fonctionnement.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-collectivite-confirmation",
    path: "/inscription/responsable/step/collectivite-confirmation",
    meta: collectivite_45confirmation3nQOZiJkCWMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/responsable/step/collectivite-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-collectivite",
    path: "/inscription/responsable/step/collectivite",
    meta: collectivite8CXZjIjB2XMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/responsable/step/collectivite.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation-confirmation",
    path: "/inscription/responsable/step/organisation-confirmation",
    meta: organisation_45confirmation0uvnB1NaniMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/responsable/step/organisation-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation-details",
    path: "/inscription/responsable/step/organisation-details",
    meta: organisation_45detailsTCyJ93fJLxMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/responsable/step/organisation-details.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation-images",
    path: "/inscription/responsable/step/organisation-images",
    meta: organisation_45images6W04KxQ8VdMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/responsable/step/organisation-images.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation",
    path: "/inscription/responsable/step/organisation",
    meta: organisationw7cQ9eemPiMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/responsable/step/organisation.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-profile",
    path: "/inscription/responsable/step/profile",
    meta: profilezBTyiPVPbRMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/inscription/responsable/step/profile.vue").then(m => m.default || m)
  },
  {
    name: "invitations-hash",
    path: "/invitations/:hash()",
    meta: _91hash_93g20uvCFVPQMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/invitations/[hash].vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logintSIX407gGGMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "maintenance-admin",
    path: "/maintenance/admin",
    meta: adminrkKD6TeLf8Meta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/maintenance/admin.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: indexOUNRwrTd51Meta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/maintenance/index.vue").then(m => m.default || m)
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: "messages-id",
    path: "/messages/:id()",
    meta: _91id_93wVEfXGR85kMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/messages/[id].vue").then(m => m.default || m)
  },
  {
    name: "messages",
    path: "/messages",
    meta: indexS6EgUOAqnUMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "messages-modeles-add",
    path: "/messages/modeles/add",
    meta: addixUBnz6zH5Meta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/messages/modeles/add.vue").then(m => m.default || m)
  },
  {
    name: "messages-modeles",
    path: "/messages/modeles",
    meta: indexL2EuWAv9k3Meta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/messages/modeles/index.vue").then(m => m.default || m)
  },
  {
    name: "missions-benevolat-id-slug",
    path: "/missions-benevolat/:id()/:slug()",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/missions-benevolat/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "missions-benevolat-id",
    path: "/missions-benevolat/:id()",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/missions-benevolat/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "missions-benevolat",
    path: "/missions-benevolat",
    meta: indexyAYEIvC4wEMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/missions-benevolat/index.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: notificationsefMKdgKZqNMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: "organisations-slug",
    path: "/organisations/:slug()",
    meta: _91slug_93Z32UmxDdveMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/organisations/[slug].vue").then(m => m.default || m)
  },
  {
    name: "organisations",
    path: "/organisations",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password-reset/:token()",
    meta: _91token_93vdMbxg1i94Meta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: indexvhplbkNpnjMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: "plan-du-site",
    path: "/plan-du-site",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/plan-du-site.vue").then(m => m.default || m)
  },
  {
    name: "politique-de-confidentialite",
    path: "/politique-de-confidentialite",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/politique-de-confidentialite.vue").then(m => m.default || m)
  },
  {
    name: "profile-alertes",
    path: "/profile/alertes",
    meta: indexE6xBz3hIkmMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/profile/alertes/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-charte-bon-fonctionnement",
    path: "/profile/charte-bon-fonctionnement",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/profile/charte-bon-fonctionnement.vue").then(m => m.default || m)
  },
  {
    name: "profile-edit",
    path: "/profile/edit",
    meta: editRwvfHfDZ7EMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: "profile-favoris",
    path: "/profile/favoris",
    meta: favoris0Xn6qtN75zMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/profile/favoris.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexJXKMuolDtnMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-missions",
    path: "/profile/missions",
    meta: missionsNAZ0hqjCUWMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/profile/missions.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications",
    path: "/profile/notifications",
    meta: notificationsHxoiPuGr5YMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: "profile-preferences",
    path: "/profile/preferences",
    meta: preferencesQ7FtJ8HQDUMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/profile/preferences.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: settingsKbnLS0aRQXMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: "quiz-decembre-ensemble",
    path: "/quiz/decembre-ensemble",
    meta: decembre_45ensemblePgHLS9FPWSMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/quiz/decembre-ensemble.vue").then(m => m.default || m)
  },
  {
    name: "quiz-generique",
    path: "/quiz/generique",
    meta: generiquesuBAPQmbrvMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/quiz/generique.vue").then(m => m.default || m)
  },
  {
    name: "quiz-mgen",
    path: "/quiz/mgen",
    meta: mgenjqR4E2FUxaMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/quiz/mgen.vue").then(m => m.default || m)
  },
  {
    name: "quiz-printemps-pour-la-planete",
    path: "/quiz/printemps-pour-la-planete",
    meta: printemps_45pour_45la_45planetejgLbgTONKlMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/quiz/printemps-pour-la-planete.vue").then(m => m.default || m)
  },
  {
    name: "quiz-septembre-pour-apprendre",
    path: "/quiz/septembre-pour-apprendre",
    meta: septembre_45pour_45apprendreNnCi3EerRiMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/quiz/septembre-pour-apprendre.vue").then(m => m.default || m)
  },
  {
    name: "reseaux-slug",
    path: "/reseaux/:slug()",
    meta: _91slug_93Bvzc0za9uoMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/reseaux/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stats",
    path: "/stats",
    meta: indexcJ6d0MIIpOMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/stats/index.vue").then(m => m.default || m)
  },
  {
    name: "stats-missions",
    path: "/stats/missions",
    meta: missionsvvoZgGUulKMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/stats/missions.vue").then(m => m.default || m)
  },
  {
    name: "stats-organisations",
    path: "/stats/organisations",
    meta: organisationstDpIMuzlGvMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/stats/organisations.vue").then(m => m.default || m)
  },
  {
    name: "stats-participations",
    path: "/stats/participations",
    meta: participationspo7UM9UfsHMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/stats/participations.vue").then(m => m.default || m)
  },
  {
    name: "stats-places",
    path: "/stats/places",
    meta: placesrjsHweGncYMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/stats/places.vue").then(m => m.default || m)
  },
  {
    name: "stats-utilisateurs",
    path: "/stats/utilisateurs",
    meta: utilisateurs6GKenpluJDMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/stats/utilisateurs.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-search-archived-users",
    path: "/support/actions/search-archived-users",
    meta: search_45archived_45users1qW4q43BlTMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/actions/search-archived-users.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-transfert-organisation",
    path: "/support/actions/transfert-organisation",
    meta: transfert_45organisationbQaoTamquSMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/actions/transfert-organisation.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-user-reset-context-role",
    path: "/support/actions/user-reset-context-role",
    meta: user_45reset_45context_45roleEVyjgOaHMRMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/actions/user-reset-context-role.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-user-reset-password-link",
    path: "/support/actions/user-reset-password-link",
    meta: user_45reset_45password_45linkW3eSpTZCqtMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/actions/user-reset-password-link.vue").then(m => m.default || m)
  },
  {
    name: "support-contents-doublons-organisations",
    path: "/support/contents/doublons-organisations",
    meta: doublons_45organisationsLSClzPDOAYMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/contents/doublons-organisations.vue").then(m => m.default || m)
  },
  {
    name: "support-contents-doublons-territoires",
    path: "/support/contents/doublons-territoires",
    meta: doublons_45territoiresTmz2b0YvFdMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/contents/doublons-territoires.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    meta: indexsUoikpUp7gMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "support-referents-activity-logs",
    path: "/support/referents/activity-logs",
    meta: activity_45logs94j3uCHRXAMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/referents/activity-logs.vue").then(m => m.default || m)
  },
  {
    name: "support-referents-waiting-actions",
    path: "/support/referents/waiting-actions",
    meta: waiting_45actionsMCQoImYhYJMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/referents/waiting-actions.vue").then(m => m.default || m)
  },
  {
    name: "support-responsables-missions-outdated",
    path: "/support/responsables/missions-outdated",
    meta: missions_45outdatedfDKXGB2Cm8Meta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/responsables/missions-outdated.vue").then(m => m.default || m)
  },
  {
    name: "support-responsables-participations-to-be-treated",
    path: "/support/responsables/participations-to-be-treated",
    meta: participations_45to_45be_45treatedqZd1NLfYn3Meta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/support/responsables/participations-to-be-treated.vue").then(m => m.default || m)
  },
  {
    name: "temoignages-token",
    path: "/temoignages/:token()",
    meta: _91token_93ZP4lK2DaTtMeta || {},
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/temoignages/[token].vue").then(m => m.default || m)
  },
  {
    name: "territoires",
    path: "/territoires",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/territoires/index.vue").then(m => m.default || m)
  },
  {
    name: "villes-slug",
    path: "/villes/:slug()",
    component: () => import("/build/7bffa8d8-6c1c-4be0-b374-00a3978a7835/pages/villes/[slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubZECJ9rda2hMeta?.name,
    path: "/register/volontaire/:pathMatch(.*)",
    component: component_45stubZECJ9rda2h
  },
  {
    name: component_45stubZECJ9rda2hMeta?.name,
    path: "/register/responsable/:pathMatch(.*)",
    component: component_45stubZECJ9rda2h
  },
  {
    name: component_45stubZECJ9rda2hMeta?.name,
    path: "/user/settings",
    component: component_45stubZECJ9rda2h
  },
  {
    name: component_45stubZECJ9rda2hMeta?.name,
    path: "/user/:pathMatch(.*)",
    component: component_45stubZECJ9rda2h
  },
  {
    name: component_45stubZECJ9rda2hMeta?.name,
    path: "/dashboard/structure/:pathMatch(.*)",
    component: component_45stubZECJ9rda2h
  },
  {
    name: component_45stubZECJ9rda2hMeta?.name,
    path: "/dashboard/mission/:pathMatch(.*)",
    component: component_45stubZECJ9rda2h
  },
  {
    name: component_45stubZECJ9rda2hMeta?.name,
    path: "/dashboard/participation/:pathMatch(.*)",
    component: component_45stubZECJ9rda2h
  },
  {
    name: component_45stubZECJ9rda2hMeta?.name,
    path: "/dashboard/profile/:pathMatch(.*)",
    component: component_45stubZECJ9rda2h
  },
  {
    name: component_45stubZECJ9rda2hMeta?.name,
    path: "/dashboard/reseaux/:pathMatch(.*)",
    component: component_45stubZECJ9rda2h
  },
  {
    name: component_45stubZECJ9rda2hMeta?.name,
    path: "/inscription/organisation",
    component: component_45stubZECJ9rda2h
  },
  {
    name: component_45stubZECJ9rda2hMeta?.name,
    path: "/statistiques",
    component: component_45stubZECJ9rda2h
  },
  {
    name: component_45stubZECJ9rda2hMeta?.name,
    path: "/activites/accompagnement-aux-activites-sportives",
    component: component_45stubZECJ9rda2h
  },
  {
    name: component_45stubZECJ9rda2hMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubZECJ9rda2h
  }
]